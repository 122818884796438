import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment/moment';
import 'moment/locale/fr';
import axios from 'axios';

import '../../Assets/css/detailCandidatAdmin.scss';
import { AuthContext } from '../../context/AuthContext';
import logoParDefaut from '../../Assets/img/SOTW_logo (5).webp';

const DetailCandidatureAdmin = () => {
  const redirect = useNavigate();
  const { getUrl, urlFile } = useContext(AuthContext);
  const { id } = useParams();
  const effectRan = useRef(false);
  const [candidat, setCandidat] = useState([]);

  const [candidatCV, setCandidatCV] = useState([]);
  const [candidatLM, setCandidatLM] = useState([]);

  useEffect(() => {
    const getCandidat = async (idCandidat) => {
      axios
        .get(`${process.env.REACT_APP_API_URL}api/user/candidat/${idCandidat}`)
        .then((res) => {
          setCandidat(res.data);
          setCandidatCV(res.data.listCV);
          setCandidatLM(res.data.listLM);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    if (effectRan.current === false) {
      getCandidat(id);
      getUrl();
    }
    return () => {
      effectRan.current = true;
    };
  }, [id]);

  return (
    <div className="outerDivDetailEvent">
      <div className="innerDivDetailEvent">
        <p className="linkRetour" onClick={() => redirect(-1)}>
          {' '}
          &#60; Retour
        </p>
        <h2>Détail du candidat</h2>
        {/* TOP CARD */}

        <div className="topCard">
          {/* LEFT */}
          <div className="topLeft">
            <img
              src={
                candidat && candidat.uploadLogo
                  ? `${urlFile.split('.com/')[0]}.com/${candidat.uploadLogo}`
                  : logoParDefaut
              }
              alt="profil du candidat"
            />
          </div>
          {/* RIGHT */}
          <div className="topRight">
            <div className="titre">
              <h4>
                <b>
                  {candidat.nom} {candidat.prenom}
                </b>
              </h4>
              <i>
                <p>{candidat.localisation}</p>
              </i>
            </div>
            <p style={{ marginTop: '12px' }}>{candidat.secteurActivite}</p>
            <p>
              Date de naissance :{' '}
              <b>{moment(candidat.dateNaissance).locale('fr').format('LL')}</b>
            </p>
            <p>{candidat.email}</p>
            <p>
              Date d'inscription à la plateforme :{' '}
              <b>{moment(candidat.createdAt).locale('fr').format('LL')}</b>
            </p>
          </div>
        </div>
        {/* CV */}
        <div className="divTable first">
          <table className="innerTable">
            <thead>
              <tr>
                <th
                  style={{
                    width: '70%',
                    textAlign: 'left',
                    paddingLeft: '15px',
                  }}
                >
                  Liste des CV téléchargés
                </th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {candidatCV.length > 0 ? (
                candidatCV.map((val, key) => {
                  return (
                    <tr key={val._id}>
                      <td style={{ textAlign: 'left', paddingLeft: '15px' }}>
                        <span>{val.file1_path.split('-')[1]}</span>
                      </td>
                      <td>
                        <a
                          download
                          href={`${urlFile.split('.com/')[0]}.com/${
                            val.file1_path
                          }`}
                        >
                          Telecharger le CV
                        </a>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td>Pas encore de CV</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {/* LM */}
        <div className="divTable">
          <table className="innerTable">
            <thead>
              <tr>
                <th
                  style={{
                    width: '70%',
                    textAlign: 'left',
                    paddingLeft: '15px',
                  }}
                >
                  Liste des LM téléchargées
                </th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {candidatLM.length > 0 ? (
                candidatLM.map((val, key) => {
                  return (
                    <tr key={val._id}>
                      <td style={{ textAlign: 'left', paddingLeft: '15px' }}>
                        <span>{val.file1_path.split('-')[1]}</span>
                      </td>
                      <td>
                        <a
                          download
                          href={`${urlFile.split('.com/')[0]}.com/${
                            val.file1_path
                          }`}
                        >
                          Telecharger le LM
                        </a>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td>Pas encore de Lettre de motivation</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default DetailCandidatureAdmin;
