import React, { useState, useContext, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';
import { reponseRefuse, reponseAttente, reponseValide } from '../Utils/reponse';

import '../Assets/css/candidatureOffre.scss';
import Pagination from '../Components/Pagination';

const CandidatureOffre = () => {
  const redirect = useNavigate();
  const { uid, candidat, entreprise, admin, getUrl, urlFile } =
    useContext(AuthContext);

  const [isTalentGlobalLoading, setIsTalentGlobalLoading] =
    useState('Chargement ...');
  const [isAccepteLoading, setIsAccepteLoading] = useState('Chargement ...');
  const [isRefuseLoading, setIsRefuseLoading] = useState('Chargement ...');
  const [isAttenteLoading, setIsAttenteLoading] = useState('Chargement ...');

  const effectRan = useRef(false);

  const [showTalent, setShowTalent] = useState(true);
  const [isAccepted, setIsAccepted] = useState(false);
  const [isRefused, setIsRefused] = useState(false);
  const [enAttente, setEnAttente] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);

  const handleClickTalent = () => {
    if (setShowTalent) {
      setShowTalent(true);
    } else {
      setShowTalent(true);
    }
    setIsAccepted(false);
    setIsRefused(false);
    setEnAttente(false);
  };

  const handleClickAccepted = () => {
    setShowTalent(false);
    if (setIsAccepted) {
      setIsAccepted(true);
    } else {
      setIsAccepted(true);
    }
    setIsRefused(false);
    setEnAttente(false);
  };

  const handleClickRefused = () => {
    setShowTalent(false);
    setIsAccepted(false);
    if (setIsRefused) {
      setIsRefused(true);
    } else {
      setIsRefused(true);
    }
    setEnAttente(false);
  };

  const handleClickAttente = () => {
    setShowTalent(false);
    setIsAccepted(false);
    setIsRefused(false);
    if (setEnAttente) {
      setEnAttente(true);
    } else {
      setEnAttente(true);
    }
  };

  const { id } = useParams();

  const [offre, setOffre] = useState([]);
  const [listCandidat, setListCandidat] = useState([]);

  const [listeCandidatAttente, setListCandidatAttente] = useState([]);
  const [listCandidatAccepte, setListCandidatAccepte] = useState([]);
  const [listCandidatRefuse, setListCandidatRefuse] = useState([]);
  const [listeCandidatMettreEnAttente, setListCandidatMettreEnAttente] =
    useState([]);

  const repondreAttente = async (
    response,
    candida,
    offreId,
    email,
    titrePoste
  ) => {
    await axios({
      method: 'PUT',
      url: `${process.env.REACT_APP_API_URL}api/offre/validate/${offreId}`,
      data: {
        candidatId: candida,
        resultat: response,
        email: email,
        texte: reponseAttente(titrePoste),
      },
    })
      .then((res) => {
        if (res.data.errors) {
          console.log(res.data.errors);
        } else {
          redirect(`/candidatureOffre/${id}`);
          window.location.reload(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const repondreValide = async (
    response,
    candida,
    offreId,
    email,
    titrePoste
  ) => {
    await axios({
      method: 'PUT',
      url: `${process.env.REACT_APP_API_URL}api/offre/validate/${offreId}`,
      data: {
        candidatId: candida,
        resultat: response,
        email: email,
        texte: reponseValide(titrePoste),
      },
    })
      .then((res) => {
        if (res.data.errors) {
          console.log(res.data.errors);
        } else {
          redirect(`/candidatureOffre/${id}`);
          window.location.reload(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const repondreRefuse = async (
    response,
    candida,
    offreId,
    email,
    titrePoste
  ) => {
    await axios({
      method: 'PUT',
      url: `${process.env.REACT_APP_API_URL}api/offre/validate/${offreId}`,
      data: {
        candidatId: candida,
        resultat: response,
        email: email,
        texte: reponseRefuse(titrePoste),
      },
    })
      .then((res) => {
        if (res.data.errors) {
          console.log(res.data.errors);
        } else {
          redirect(`/candidatureOffre/${id}`);
          window.location.reload(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const getOffreWithCandidat = async () => {
      const idCandidatMettreEnAttente = [];
      const idCandidatRefuse = [];
      const idCandidatAccepte = [];
      const idCandidatAttente = [];

      const getOffre = await axios.get(
        `${process.env.REACT_APP_API_URL}api/offre/${id}`
      );

      // ============================================= //

      // on met dans le candidatureAttente la liste des candidature vient d'arriver
      const candidatureAttente = getOffre.data.listCandidat.filter(
        (candidat) =>
          candidat.resultat === 'envoye' && candidat.isValideCV === true
      );

      // on met dans le candidatureAccepte la liste des candidature accepté
      const candidatureAccepte = getOffre.data.listCandidat.filter(
        (candidat) =>
          candidat.resultat === 'accepté' && candidat.isValideCV === true
      );

      // on met dans le candidatureRefuse la liste des candidature refuse
      const candidatureRefuse = getOffre.data.listCandidat.filter(
        (candidat) =>
          candidat.resultat === 'Réfusée' && candidat.isValideCV === true
      );

      const candidatureMettreEnAttente = getOffre.data.listCandidat.filter(
        (candidat) =>
          candidat.resultat === 'attente' && candidat.isValideCV === true
      );

      // on met dans un tableau la liste des id candidats refuse
      for (let i = 0; i < candidatureRefuse.length; i++) {
        idCandidatRefuse.push(getOffre.data.listCandidat[i].candidatId);
      }

      // on met dans un tableau la liste des id candidats accepte
      for (let i = 0; i < candidatureAccepte.length; i++) {
        idCandidatAccepte.push(getOffre.data.listCandidat[i].candidatId);
      }

      // on met dans un tableau la liste des id candidats attente
      for (let i = 0; i < candidatureAttente.length; i++) {
        idCandidatAttente.push(getOffre.data.listCandidat[i].candidatId);
      }

      // on met dans un tableau la liste des id candidats mettre en attente
      for (let i = 0; i < candidatureMettreEnAttente.length; i++) {
        idCandidatMettreEnAttente.push(
          getOffre.data.listCandidat[i].candidatId
        );
      }

      // ============================================= //
      const getInfoCandidatAttente = await Promise.all(
        idCandidatAttente.map((i) =>
          axios.get(`${process.env.REACT_APP_API_URL}api/user/candidat/${i}`)
        )
      );
      const getInfoCandidatAccepte = await Promise.all(
        idCandidatAccepte.map((i) =>
          axios.get(`${process.env.REACT_APP_API_URL}api/user/candidat/${i}`)
        )
      );
      const getInfoCandidatRefuse = await Promise.all(
        idCandidatRefuse.map((i) =>
          axios.get(`${process.env.REACT_APP_API_URL}api/user/candidat/${i}`)
        )
      );
      const getInfoCandidatMettreEnAttente = await Promise.all(
        idCandidatMettreEnAttente.map((i) =>
          axios.get(`${process.env.REACT_APP_API_URL}api/user/candidat/${i}`)
        )
      );
      // ============================================= //
      for (let i = 0; i < getInfoCandidatAttente.length; i++) {
        setListCandidatAttente((list) => [
          ...list,
          {
            _id: candidatureAttente[i]._id,
            idCandida: getInfoCandidatAttente[i].data._id,
            nom: getInfoCandidatAttente[i].data.nom,
            prenom: getInfoCandidatAttente[i].data.prenom,
            email: getInfoCandidatAttente[i].data.email,
            resultat: candidatureAttente[i].resultat,
            cv_path: candidatureAttente[i].file1_path,
            lm_path: candidatureAttente[i].file2_path,
            isValideCV: candidatureAttente[i].isValideCV,
            isValideLM: candidatureAttente[i].isValideLM,
            documentTexte: candidatureAttente[i].documentTexte,
          },
        ]);
      }
      listeCandidatAttente.length === 0 &&
        setIsTalentGlobalLoading('Pas encore de candidat');

      for (let i = 0; i < getInfoCandidatAccepte.length; i++) {
        setListCandidatAccepte((list) => [
          ...list,
          {
            _id: candidatureAccepte[i]._id,
            idCandida: getInfoCandidatAccepte[i].data._id,
            nom: getInfoCandidatAccepte[i].data.nom,
            prenom: getInfoCandidatAccepte[i].data.prenom,
            email: getInfoCandidatAccepte[i].data.email,
            resultat: candidatureAccepte[i].resultat,
            cv_path: candidatureAccepte[i].file1_path,
            lm_path: candidatureAccepte[i].file2_path,
            isValideCV: candidatureAccepte[i].isValideCV,
            isValideLM: candidatureAccepte[i].isValideLM,
            documentTexte: candidatureAccepte[i].documentTexte,
            documentTexte2: candidatureAccepte[i].documentTexte2,
          },
        ]);
      }
      listCandidatAccepte.length === 0 &&
        setIsAccepteLoading('Pas encore de candidat accepté');

      for (let i = 0; i < getInfoCandidatRefuse.length; i++) {
        setListCandidatRefuse((list) => [
          ...list,
          {
            _id: candidatureRefuse[i]._id,
            idCandida: getInfoCandidatRefuse[i].data._id,
            nom: getInfoCandidatRefuse[i].data.nom,
            prenom: getInfoCandidatRefuse[i].data.prenom,
            email: getInfoCandidatRefuse[i].data.email,
            resultat: candidatureRefuse[i].resultat,
            cv_path: candidatureRefuse[i].file1_path,
            lm_path: candidatureRefuse[i].file2_path,
            isValideCV: candidatureRefuse[i].isValideCV,
            isValideLM: candidatureRefuse[i].isValideLM,
            documentTexte: candidatureRefuse[i].documentTexte,
            documentTexte2: candidatureRefuse[i].documentTexte2,
          },
        ]);
      }
      listCandidatRefuse.length === 0 &&
        setIsRefuseLoading('Pas encore de candidat refusé');

      for (let i = 0; i < getInfoCandidatMettreEnAttente.length; i++) {
        setListCandidatMettreEnAttente((list) => [
          ...list,
          {
            _id: candidatureMettreEnAttente[i]._id,
            idCandida: getInfoCandidatMettreEnAttente[i].data._id,
            nom: getInfoCandidatMettreEnAttente[i].data.nom,
            prenom: getInfoCandidatMettreEnAttente[i].data.prenom,
            email: getInfoCandidatMettreEnAttente[i].data.email,
            resultat: candidatureMettreEnAttente[i].resultat,
            cv_path: candidatureMettreEnAttente[i].file1_path,
            lm_path: candidatureMettreEnAttente[i].file2_path,
            isValideCV: candidatureMettreEnAttente[i].isValideCV,
            isValideLM: candidatureMettreEnAttente[i].isValideLM,
            documentTexte: candidatureMettreEnAttente[i].documentTexte,
            documentTexte2: candidatureMettreEnAttente[i].documentTexte2,
          },
        ]);
      }
      listeCandidatMettreEnAttente.length === 0 &&
        setIsAttenteLoading('Pas encore de candidat mis en attente');

      setOffre(getOffre.data);
    };
    if (effectRan.current === false) {
      getOffreWithCandidat();
      getUrl();
    }
    return () => {
      effectRan.current = true;
    };
  }, []);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  return (
    <div className="divCandidature">
      <div className="innerCandidature">
        <p className="linkRetour" onClick={() => redirect(-1)}>
          {' '}
          &#60; Retour
          {console.log(listeCandidatAttente)}
        </p>
        <h2>Candidatures</h2>
        <h4 className="postTitle">
          <b>Poste : </b>
          {offre.intitulePoste}
        </h4>
        <div className="navAndTable">
          <div className="navigation">
            <h4
              onClick={handleClickTalent}
              className={showTalent ? 'active' : undefined}
            >
              Les talents{' '}
              {listeCandidatAttente.length === 0
                ? ' '
                : '[' + listeCandidatAttente.length + ']'}
            </h4>
            <h4
              onClick={handleClickAccepted}
              className={isAccepted ? 'active' : undefined}
            >
              Accepter{' '}
              {listCandidatAccepte.length === 0
                ? ' '
                : '[' + listCandidatAccepte.length + ']'}
            </h4>
            <h4
              onClick={handleClickRefused}
              className={isRefused ? 'active' : undefined}
            >
              Refuser{' '}
              {listCandidatRefuse.length === 0
                ? ' '
                : '[' + listCandidatRefuse.length + ']'}
            </h4>
            <h4
              onClick={handleClickAttente}
              className={enAttente ? 'active' : undefined}
            >
              En attente{' '}
              {listeCandidatMettreEnAttente.length === 0
                ? ' '
                : '[' + listeCandidatMettreEnAttente.length + ']'}
            </h4>
          </div>

          {/* TALENTS */}
          {showTalent && (
            <div className="divTable">
              <table className="innerTable">
                <thead>
                  <tr>
                    <th
                      style={{
                        width: '75%',
                        textAlign: 'left',
                        paddingLeft: '15px',
                      }}
                    >
                      Liste des talents
                    </th>
                    {entreprise && <th>Action</th>}
                  </tr>
                </thead>
                <tbody>
                  {listeCandidatAttente.length > 0 ? (
                    listeCandidatAttente
                      .slice(indexOfFirstPost, indexOfLastPost)
                      .map((val) => (
                        <tr key={val._id}>
                          <td
                            style={{
                              textAlign: 'left',
                              paddingLeft: '15px',
                            }}
                          >
                            <span>{val.nom + ' ' + val.prenom} </span>
                            <a
                              download
                              href={`${urlFile.split('.com/')[0]}.com/${
                                val.cv_path
                              }`}
                            >
                              Telecharger le CV
                            </a>{' '}
                            &nbsp; &nbsp;{' '}
                            {val.lm_path && (
                              <a
                                download
                                href={`${urlFile.split('.com/')[0]}.com/${
                                  val.lm_path
                                }`}
                              >
                                Telecharger la LM
                              </a>
                            )}{' '}
                            &nbsp; &nbsp;{' '}
                            {val.documentTexte && (
                              <a
                                download
                                href={`${urlFile.split('.com/')[0]}.com/${
                                  val.documentTexte
                                }`}
                              >
                                Telecharger le fichier 1
                              </a>
                            )}{' '}
                            &nbsp; &nbsp;{' '}
                            {val.documentTexte2 && (
                              <a
                                download
                                href={`${urlFile.split('.com/')[0]}.com/${
                                  val.documentTexte2
                                }`}
                              >
                                Telecharger le fichier 2
                              </a>
                            )}
                          </td>
                          <td className="action">
                            <button
                              style={{ border: 'none' }}
                              onClick={() =>
                                repondreValide(
                                  'accepté',
                                  val.idCandida,
                                  id,
                                  val.email,
                                  offre.intitulePoste
                                )
                              }
                            >
                              Accepter
                            </button>

                            <button
                              style={{ border: 'none' }}
                              onClick={() =>
                                repondreRefuse(
                                  'Réfusée',
                                  val.idCandida,
                                  id,
                                  val.email,
                                  offre.intitulePoste
                                )
                              }
                            >
                              Refuser
                            </button>
                            <button
                              style={{ border: 'none' }}
                              onClick={() =>
                                repondreAttente(
                                  'attente',
                                  val.idCandida,
                                  id,
                                  val.email,
                                  offre.intitulePoste
                                )
                              }
                            >
                              Mettre en attente
                            </button>
                          </td>
                        </tr>
                      ))
                  ) : (
                    <tr>
                      <td>{isTalentGlobalLoading}</td>
                    </tr>
                  )}
                  {/* <tr>
                    <td>Pas encore de candidat</td>
                  </tr> */}
                </tbody>
              </table>
            </div>
          )}

          {/* ACCEPTEE */}
          {isAccepted && (
            <div className="divTable">
              <table className="innerTable">
                <thead>
                  <tr>
                    <th
                      style={{
                        width: '75%',
                        textAlign: 'left',
                        paddingLeft: '15px',
                      }}
                    >
                      Liste des talents acceptés
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {listCandidatAccepte.length > 0 ? (
                    listCandidatAccepte
                      .slice(indexOfFirstPost, indexOfLastPost)
                      .map((val) => (
                        <tr key={val._id}>
                          <td
                            style={{
                              textAlign: 'left',
                              paddingLeft: '15px',
                              background: 'rgba(20, 220, 97, 0.346)',
                            }}
                          >
                            <span>{val.nom + ' ' + val.prenom} </span>
                            <a
                              download
                              href={`${urlFile.split('.com/')[0]}.com/${
                                val.cv_path
                              }`}
                            >
                              Telecharger le CV
                            </a>{' '}
                            &nbsp; &nbsp;{' '}
                            {val.lm_path && (
                              <a
                                download
                                href={`${urlFile.split('.com/')[0]}.com/${
                                  val.lm_path
                                }`}
                              >
                                Telecharger la LM
                              </a>
                            )}{' '}
                            &nbsp; &nbsp;{' '}
                            {val.documentTexte && (
                              <a
                                download
                                href={`${urlFile.split('.com/')[0]}.com/${
                                  val.documentTexte
                                }`}
                              >
                                Telecharger le fichier 1
                              </a>
                            )}{' '}
                            &nbsp; &nbsp;{' '}
                            {val.documentTexte2 && (
                              <a
                                download
                                href={`${urlFile.split('.com/')[0]}.com/${
                                  val.documentTexte2
                                }`}
                              >
                                Telecharger le fichier 2
                              </a>
                            )}
                          </td>
                        </tr>
                      ))
                  ) : (
                    <tr>
                      <td>{isAccepteLoading}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}

          {/* REFUSEE */}
          {isRefused && (
            <div className="divTable">
              <table className="innerTable">
                <thead>
                  <tr>
                    <th
                      style={{
                        width: '75%',
                        textAlign: 'left',
                        paddingLeft: '15px',
                      }}
                    >
                      Liste des talents refusés
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {listCandidatRefuse.length > 0 ? (
                    listCandidatRefuse
                      .slice(indexOfFirstPost, indexOfLastPost)
                      .map((val) => (
                        <tr key={val._id}>
                          <td
                            style={{
                              textAlign: 'left',
                              paddingLeft: '15px',
                              background: 'rgba(220, 20, 20, 0.258)',
                            }}
                          >
                            <span>{val.nom + ' ' + val.prenom}</span>
                            <a
                              download
                              href={`${urlFile.split('.com/')[0]}.com/${
                                val.cv_path
                              }`}
                            >
                              Telecharger le CV
                            </a>{' '}
                            &nbsp; &nbsp;{' '}
                            {val.lm_path && (
                              <a
                                download
                                href={`${urlFile.split('.com/')[0]}.com/${
                                  val.lm_path
                                }`}
                              >
                                Telecharger la LM
                              </a>
                            )}
                          </td>
                        </tr>
                      ))
                  ) : (
                    <tr>
                      <td>{isRefuseLoading}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}

          {/* MISE EN ATTENTE */}
          {enAttente && (
            <div className="divTable">
              <table className="innerTable">
                <thead>
                  <tr>
                    <th
                      style={{
                        width: '75%',
                        textAlign: 'left',
                        paddingLeft: '15px',
                      }}
                    >
                      Liste des talents mis en attente
                    </th>
                    {entreprise && <th>Action</th>}
                  </tr>
                </thead>
                <tbody>
                  {listeCandidatMettreEnAttente.length > 0 ? (
                    listeCandidatMettreEnAttente
                      .slice(indexOfFirstPost, indexOfLastPost)
                      .map((val) => (
                        <tr key={val._id}>
                          <td
                            style={{
                              textAlign: 'left',
                              paddingLeft: '15px',
                              background: 'rgba(220, 153, 20, 0.346)',
                            }}
                          >
                            <span>{val.nom + ' ' + val.prenom} </span>
                            <a
                              download
                              href={`${urlFile.split('.com/')[0]}.com/${
                                val.cv_path
                              }`}
                            >
                              Telecharger le CV
                            </a>{' '}
                            &nbsp; &nbsp;{' '}
                            {val.lm_path && (
                              <a
                                download
                                href={`${urlFile.split('.com/')[0]}.com/${
                                  val.lm_path
                                }`}
                              >
                                Telecharger la LM
                              </a>
                            )}
                            {/* <a href="#">Telecharger le fichier supplementaire</a> */}
                          </td>
                          <td className="action">
                            <button
                              style={{ border: 'none' }}
                              onClick={() =>
                                repondreValide(
                                  'accepté',
                                  val.idCandida,
                                  id,
                                  val.email,
                                  offre.intitulePoste
                                )
                              }
                            >
                              Accepter
                            </button>

                            <button
                              style={{ border: 'none' }}
                              onClick={() =>
                                repondreRefuse(
                                  'Réfusée',
                                  val.idCandida,
                                  id,
                                  val.email,
                                  offre.intitulePoste
                                )
                              }
                            >
                              Refuser
                            </button>
                            <button
                              style={{ border: 'none' }}
                              onClick={() =>
                                repondreAttente(
                                  'attente',
                                  val.idCandida,
                                  id,
                                  val.email,
                                  offre.intitulePoste
                                )
                              }
                            >
                              Mettre en attente
                            </button>
                          </td>
                        </tr>
                      ))
                  ) : (
                    <tr>
                      <td>{isAttenteLoading}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>
        {showTalent && (
          <Pagination
            postsPerPage={postsPerPage}
            totalPosts={listeCandidatAttente.length}
            paginate={paginate}
          />
        )}
        {isAccepted && (
          <Pagination
            postsPerPage={postsPerPage}
            totalPosts={listCandidatAccepte.length}
            paginate={paginate}
          />
        )}
        {isRefused && (
          <Pagination
            postsPerPage={postsPerPage}
            totalPosts={listCandidatRefuse.length}
            paginate={paginate}
          />
        )}
        {enAttente && (
          <Pagination
            postsPerPage={postsPerPage}
            totalPosts={listeCandidatMettreEnAttente.length}
            paginate={paginate}
          />
        )}
      </div>
    </div>
  );
};

export default CandidatureOffre;
