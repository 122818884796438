import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from "moment/moment";
import "moment/locale/fr";
import { toast } from "react-toastify";

import "../Assets/css/validation.scss";
import Pagination from "../Components/Pagination";

const ValidationOffre = () => {
  const redirect = useNavigate();
  const [offreValide, setOffreValide] = useState([]);
  const [offreNonValide, setOffreNonValide] = useState([]);
  const [offreDEpublie, setOffreDepublie] = useState([]);

  const [showOffreEnCours, setShowOffreEnCours] = useState(true);
  const [showOffreValider, setShowOffreValider] = useState(false);
  const [showOffreDepublier, setShowOffreDepublier] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(5);

  const [isLoading, setIsLoading] = useState(" ");
  const [isValideLoading, setIsValideLoading] = useState(" ");
  const [isDepublieLoading, setIsDepublieLoading] = useState(" ");

  // Tableau qui contient les offres à depublier
  const [offreToDepublie, SetOffreToDepublie] = useState([]);
  const [isChecked, setIsChecked] = useState(false);

  const effectRan = useRef(false);

  const Success = () =>
    toast.success("Depublication reussi avec succèss", {
      position: "top-right",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const handleClickEnCours = () => {
    if (setShowOffreEnCours) {
      setShowOffreEnCours(true);
    } else {
      setShowOffreEnCours(true);
    }
    setShowOffreValider(false);
    setShowOffreDepublier(false);
  };

  const handleClickValider = () => {
    setShowOffreEnCours(false);
    if (setShowOffreValider) {
      setShowOffreValider(true);
    } else {
      setShowOffreValider(true);
    }
    setShowOffreDepublier(false);
  };

  const handleClickDepublier = () => {
    setShowOffreEnCours(false);
    setShowOffreValider(false);
    if (setShowOffreDepublier) {
      setShowOffreDepublier(true);
    } else {
      setShowOffreDepublier(true);
    }
  };

  const depublieOffre = async (idOffre, statusOffre) => {
    await axios({
      method: "PATCH",
      url: `${process.env.REACT_APP_API_URL}api/offre/depublication/${idOffre}`,
      data: {
        depublie: statusOffre,
      },
    })
      .then((res) => {
        if (res.data.errors) {
          console.log(res.data.errors);
        } else {
          window.location.reload(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const supprimeOffre = async (idOffre) => {
    await axios({
      method: "DELETE",
      url: `${process.env.REACT_APP_API_URL}api/offre/${idOffre}`,
    })
      .then((res) => {
        if (res.data.errors) {
          console.log(res.data.errors);
        } else {
          window.location.reload(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // Ajout de chaque Element Checker dans un tableau
  const depublier = (id, event) => {
    if (event.target.checked) {
      SetOffreToDepublie([...offreToDepublie.filter((res) => res !== id), id]);
    } else {
      offreToDepublie.splice(offreToDepublie.indexOf(id), 1);
    }
    setIsChecked(!isChecked);
  };

  // depublie plusieurs offres
  const DepublieMultiple = async () => {
    await axios
      .patch(`${process.env.REACT_APP_API_URL}api/offre/depublieMultiple`, {
        idOffre: offreToDepublie,
      })
      .then((res) => {
        window.location.reload(true);
        Success();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    // const getOffreNonValide = async () => {
    //   setIsLoading("Chargement ...");
    //   const idEntreprise = [];
    //   const getJOValide = await axios({
    //     method: "GET",
    //     url: `${process.env.REACT_APP_API_URL}api/offre/nonvalide`,
    //   });
    //   for (let i = 0; i < getJOValide.data.length; i++) {
    //     idEntreprise.push(getJOValide.data[i].offreId);
    //   }
    //   const getEntreprise = await Promise.all(
    //     idEntreprise.map((i) =>
    //       axios({
    //         method: "GET",
    //         url: `${process.env.REACT_APP_API_URL}api/user/entreprise/${i}`,
    //       })
    //     )
    //   );

    //   // console.log(getEntreprise[1].data.nomEntreprise);
    //   for (let i = 0; i < getJOValide.data.length; i++) {
    //     setOffreNonValide((oldOffre) => [
    //       ...oldOffre,
    //       {
    //         _id: getJOValide.data[i]._id,
    //         intitulePoste: getJOValide.data[i].intitulePoste,
    //         isValidate: getJOValide.data[i].isValidate,
    //         depublie: getJOValide.data[i].depublie,
    //         createdAt: getJOValide.data[i].createdAt,
    //         nomEntreprise: getEntreprise[i].data.nomEntreprise,
    //       },
    //     ]);
    //   }
    //   offreNonValide.length === 0 &&
    //     setIsLoading("Aucune offre en cours de validation");
    // };

    const getOffreNonValide = async () => {
      setIsLoading("Chargement ...");
      
      try {
        const getJONonValide = await axios.get(`${process.env.REACT_APP_API_URL}api/offre/nonvalide`);
        const idEntreprise = getJONonValide.data.map(offre => offre.offreId);
        
        const getEntreprisePromises = idEntreprise.map(i =>
          axios.get(`${process.env.REACT_APP_API_URL}api/user/entreprise/${i}`)
        );
        
        const getEntrepriseResponses = await Promise.all(getEntreprisePromises);
        const entreprises = getEntrepriseResponses.map(response => response.data.nomEntreprise);
        
        const offreNonValideData = getJONonValide.data.map((offre, index) => ({
          _id: offre._id,
          intitulePoste: offre.intitulePoste,
          isValidate: offre.isValidate,
          depublie: offre.depublie,
          createdAt: offre.createdAt,
          nomEntreprise: entreprises[index],
        }));
        
        setOffreNonValide(offreNonValideData);
        
        if (offreNonValideData.length === 0) {
          setIsLoading("Aucune offre en cours de validation");
        }
      } catch (error) {
        console.error(error);
        // Gérer les erreurs de manière appropriée ici
      }
    };
    

    // const getOffreValide = async () => {
    //   setIsValideLoading("Chargement ...");
    //   const idEntreprise = [];

    //   const getJOValide = await axios({
    //     method: "GET",
    //     url: `${process.env.REACT_APP_API_URL}api/offre/valide`,
    //   });
    //   for (let i = 0; i < getJOValide.data.length; i++) {
    //     idEntreprise.push(getJOValide.data[i].offreId);
    //   }
    //   const getEntreprise = await Promise.all(
    //     idEntreprise.map((i) =>
    //       axios({
    //         method: "GET",
    //         url: `${process.env.REACT_APP_API_URL}api/user/entreprise/${i}`,
    //       })
    //     )
    //   );
    //   // console.log(getEntreprise[1].data.nomEntreprise);
    //   for (let i = 0; i < getJOValide.data.length; i++) {
    //     setOffreValide((oldOffre) => [
    //       ...oldOffre,
    //       {
    //         _id: getJOValide.data[i]._id,
    //         intitulePoste: getJOValide.data[i].intitulePoste,
    //         isValidate: getJOValide.data[i].isValidate,
    //         depublie: getJOValide.data[i].depublie,
    //         createdAt: getJOValide.data[i].createdAt,
    //         nomEntreprise: getEntreprise[i].data.nomEntreprise,
    //       },
    //     ]);
    //   }
    //   offreValide.length === 0 &&
    //     setIsValideLoading("Aucune offre validée et publiée");
    // };

    const getOffreValide = async () => {
      setIsValideLoading("Chargement ...");

      try {
        const getJOValide = await axios.get(
          `${process.env.REACT_APP_API_URL}api/offre/valide`
        );
        const idEntreprise = getJOValide.data.map((offre) => offre.offreId);

        const getEntreprisePromises = idEntreprise.map((i) =>
          axios.get(`${process.env.REACT_APP_API_URL}api/user/entreprise/${i}`)
        );

        const getEntrepriseResponses = await Promise.all(getEntreprisePromises);
        const entreprises = getEntrepriseResponses.map(
          (response) => response.data.nomEntreprise
        );

        const offreValideData = getJOValide.data.map((offre, index) => ({
          _id: offre._id,
          intitulePoste: offre.intitulePoste,
          isValidate: offre.isValidate,
          depublie: offre.depublie,
          createdAt: offre.createdAt,
          nomEntreprise: entreprises[index],
        }));

        setOffreValide(offreValideData);

        if (offreValideData.length === 0) {
          setIsValideLoading("Aucune offre validée et publiée");
        }
      } catch (error) {
        console.error(error);
        // Gérer les erreurs de manière appropriée ici
      }
    };

    // const getOffreDepublie = async () => {
    //   setIsDepublieLoading("Chargement ...");
    //   const idEntreprise = [];
    //   const getJOValide = await axios({
    //     method: "GET",
    //     url: `${process.env.REACT_APP_API_URL}api/offre/depublie`,
    //   });
    //   for (let i = 0; i < getJOValide.data.length; i++) {
    //     idEntreprise.push(getJOValide.data[i].offreId);
    //   }
    //   const getEntreprise = await Promise.all(
    //     idEntreprise.map((i) =>
    //       axios({
    //         method: "GET",
    //         url: `${process.env.REACT_APP_API_URL}api/user/entreprise/${i}`,
    //       })
    //     )
    //   );
    //   // console.log(getEntreprise[1].data.nomEntreprise);
    //   for (let i = 0; i < getJOValide.data.length; i++) {
    //     setOffreDepublie((oldOffre) => [
    //       ...oldOffre,
    //       {
    //         _id: getJOValide.data[i]._id,
    //         intitulePoste: getJOValide.data[i].intitulePoste,
    //         isValidate: getJOValide.data[i].isValidate,
    //         depublie: getJOValide.data[i].depublie,
    //         createdAt: getJOValide.data[i].createdAt,
    //         nomEntreprise: getEntreprise[i].data.nomEntreprise,
    //       },
    //     ]);
    //   }
    //   offreDEpublie.length === 0 &&
    //     setIsDepublieLoading("Aucune offre dépubliée");
    // };

    const getOffreDepublie = async () => {
      setIsDepublieLoading("Chargement ...");

      try {
        const getJODepublie = await axios.get(
          `${process.env.REACT_APP_API_URL}api/offre/depublie`
        );
        const idEntreprise = getJODepublie.data.map((offre) => offre.offreId);

        const getEntreprisePromises = idEntreprise.map((i) =>
          axios.get(`${process.env.REACT_APP_API_URL}api/user/entreprise/${i}`)
        );

        const getEntrepriseResponses = await Promise.all(getEntreprisePromises);
        const entreprises = getEntrepriseResponses.map(
          (response) => response.data.nomEntreprise
        );

        const offreDepublieData = getJODepublie.data.map((offre, index) => ({
          _id: offre._id,
          intitulePoste: offre.intitulePoste,
          isValidate: offre.isValidate,
          depublie: offre.depublie,
          createdAt: offre.createdAt,
          nomEntreprise: entreprises[index],
        }));

        setOffreDepublie(offreDepublieData);

        if (offreDepublieData.length === 0) {
          setIsDepublieLoading("Aucune offre dépubliée");
        }
      } catch (error) {
        console.error(error);
        // Gérer les erreurs de manière appropriée ici
      }
    };

    if (effectRan.current === false) {
      getOffreNonValide();
      getOffreValide();
      getOffreDepublie();
    }
    return () => {
      effectRan.current = true;
    };
  }, []);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  return (
    <div className="divValidation">
      <div className="innerValidation">
        <p className="linkRetour" onClick={() => redirect(-1)}>
          {" "}
          &#60; Retour
        </p>
        <h2>Validation des offres</h2>

        <div className="navAndTable">
          <div className="navigation">
            <h4
              onClick={handleClickEnCours}
              className={showOffreEnCours ? "active" : undefined}
            >
              En cours de validation{" "}
              {offreNonValide.length === 0
                ? " "
                : "[" + offreNonValide.length + "]"}
            </h4>
            <h4
              onClick={handleClickValider}
              className={showOffreValider ? "active" : undefined}
            >
              Validée et publiée{" "}
              {offreValide.length === 0 ? " " : "[" + offreValide.length + "]"}
            </h4>
            <h4
              onClick={handleClickDepublier}
              className={showOffreDepublier ? "active" : undefined}
            >
              Depubliée{" "}
              {offreDEpublie.length === 0
                ? " "
                : "[" + offreDEpublie.length + "]"}
            </h4>
          </div>

          {/* En cours de validation */}
          {showOffreEnCours && (
            <div className="divTable">
              <table className="innerTable">
                <thead>
                  <tr>
                    <th
                      style={{
                        width: "75%",
                        textAlign: "left",
                        paddingLeft: "15px",
                      }}
                    >
                      Liste des offre en cours de validation{" "}
                    </th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {offreNonValide.length > 0 ? (
                    offreNonValide
                      .slice(indexOfFirstPost, indexOfLastPost)
                      .map((val, key) => {
                        return (
                          <tr key={val._id}>
                            {
                              <td
                                style={{
                                  textAlign: "left",
                                  paddingLeft: "15px",
                                  background: "rgb(227 170 66 / 35%)",
                                }}
                              >
                                <span>
                                  <h4 style={{ fontFamily: "poppinsBold" }}>
                                    {!val.isValidate && val.intitulePoste}
                                  </h4>
                                  <h4>{val.nomEntreprise}</h4>
                                </span>
                                <p>
                                  {moment(val.createdAt)
                                    .locale("fr")
                                    .format("LL")}
                                </p>
                              </td>
                            }
                            {
                              <td>
                                <Link to={`/detailOffreGlobale/${val._id}`}>
                                  <button style={{ border: "none" }}>
                                    Details
                                  </button>
                                </Link>
                                <Link to={`/candidatureOffre/${val._id}`}>
                                  <button>Talent</button>
                                </Link>
                                <button
                                  style={{ border: "none" }}
                                  onClick={() => supprimeOffre(val._id)}
                                >
                                  Supprimer
                                </button>
                              </td>
                            }
                          </tr>
                        );
                      })
                  ) : (
                    <tr>
                      <td>{isLoading}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}

          {/*  Validée et publiée */}
          {showOffreValider && (
            <div className="divTable">
              <table className="innerTable">
                <thead>
                  <tr>
                    <th
                      style={{
                        width: "75%",
                        textAlign: "left",
                        paddingLeft: "15px",
                      }}
                    >
                      {offreToDepublie.length > 0 && (
                        <button
                          style={{
                            padding: "5px",
                            borderRadius: "5px",
                            border: "1px solid #272727",
                            background: "#00c4cd",
                            margin: "5px",
                          }}
                          onClick={DepublieMultiple}
                        >
                          Depublier
                        </button>
                      )}
                      Liste des offres validées et publiées
                    </th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {offreValide.length > 0 ? (
                    offreValide
                      .slice(indexOfFirstPost, indexOfLastPost)
                      .map((val, key) => {
                        return (
                          <tr key={val._id}>
                            {
                              <td
                                style={{
                                  textAlign: "left",
                                  paddingLeft: "15px",
                                  background: "rgba(20, 220, 97, 0.346)",
                                }}
                              >
                                <span>
                                  <span>
                                    <input
                                      type="checkbox"
                                      name="idEts"
                                      value={val._id}
                                      onChange={(event) =>
                                        depublier(val._id, event)
                                      }
                                    />
                                    <h4
                                      style={{
                                        fontFamily: "poppinsBold",
                                        marginLeft: "5px",
                                      }}
                                    >
                                      {val.isValidate && val.intitulePoste}
                                    </h4>
                                  </span>
                                  <h4>{val.nomEntreprise}</h4>
                                </span>
                                <span>
                                  <p>
                                    {moment(val.createdAt)
                                      .locale("fr")
                                      .format("LL")}
                                  </p>
                                  <Link
                                    to={`/validationCvLm/${val._id}`}
                                    className="validationLink"
                                  >
                                    Liste des CVs et LMs
                                  </Link>
                                </span>
                              </td>
                            }
                            {
                              <td>
                                <Link to={`/detailOffreGlobale/${val._id}`}>
                                  <button style={{ border: "none" }}>
                                    Details
                                  </button>
                                </Link>
                                <Link to={`/candidatureOffre/${val._id}`}>
                                  <button>Talent</button>
                                </Link>
                                {val.depublie === true ? (
                                  <button
                                    style={{ border: "none" }}
                                    onClick={() =>
                                      depublieOffre(val._id, false)
                                    }
                                  >
                                    Publier
                                  </button>
                                ) : (
                                  <button
                                    style={{ border: "none" }}
                                    onClick={() => depublieOffre(val._id, true)}
                                  >
                                    Depublier
                                  </button>
                                )}
                              </td>
                            }
                          </tr>
                        );
                      })
                  ) : (
                    <tr>
                      <td>{isValideLoading}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}

          {/* Depubliée */}
          {showOffreDepublier && (
            <div className="divTable">
              <table className="innerTable">
                <thead>
                  <tr>
                    <th
                      style={{
                        width: "75%",
                        textAlign: "left",
                        paddingLeft: "15px",
                      }}
                    >
                      Liste des offres depubliées
                    </th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {offreDEpublie.length > 0 ? (
                    offreDEpublie
                      .slice(indexOfFirstPost, indexOfLastPost)
                      .map((val, key) => {
                        return (
                          <tr key={val._id}>
                            {
                              <td
                                style={{
                                  textAlign: "left",
                                  paddingLeft: "15px",
                                  background: "rgb(227 170 66 / 35%)",
                                }}
                              >
                                <span>
                                  <h4 style={{ fontFamily: "poppinsBold" }}>
                                    {val.intitulePoste}
                                  </h4>
                                  <h4>{val.nomEntreprise}</h4>
                                </span>
                                <span></span>
                                <p>
                                  {moment(val.createdAt)
                                    .locale("fr")
                                    .format("LL")}
                                </p>
                              </td>
                            }
                            {val.isValidate === true &&
                              val.depublie === true && (
                                <td>
                                  <Link to={`/detailOffreGlobale/${val._id}`}>
                                    <button style={{ border: "none" }}>
                                      Details
                                    </button>
                                  </Link>
                                  <Link to={`/candidatureOffre/${val._id}`}>
                                    <button>Talent</button>
                                  </Link>
                                  {val.depublie === true ? (
                                    <button
                                      style={{ border: "none" }}
                                      onClick={() =>
                                        depublieOffre(val._id, false)
                                      }
                                    >
                                      Publier
                                    </button>
                                  ) : (
                                    <button
                                      style={{ border: "none" }}
                                      onClick={() =>
                                        depublieOffre(val._id, true)
                                      }
                                    >
                                      Depublier
                                    </button>
                                  )}
                                </td>
                              )}
                          </tr>
                        );
                      })
                  ) : (
                    <tr>
                      <td>{isDepublieLoading}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>
        {showOffreEnCours && (
          <Pagination
            postsPerPage={postsPerPage}
            totalPosts={offreNonValide.length}
            paginate={paginate}
          />
        )}
        {showOffreValider && (
          <Pagination
            postsPerPage={postsPerPage}
            totalPosts={offreValide.length}
            paginate={paginate}
          />
        )}
        {showOffreDepublier && (
          <Pagination
            postsPerPage={postsPerPage}
            totalPosts={offreDEpublie.length}
            paginate={paginate}
          />
        )}
      </div>
    </div>
  );
};

export default ValidationOffre;
